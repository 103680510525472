.bg-container {
  background-color: rgba(10, 10, 10, 0.2);
  padding: 20px;
}

.screen-card {
  background: #ffffff;
  border-radius: 10px;
}
.p-wave-login-text {
  font-weight: 600 !important;
  font-size: 36px !important;
  color: #0a0a0a;
}
.login-link {
  font-weight: 600;
  font-size: 14px;
  color: #102040;
  cursor: pointer;
  margin-left: 4px;
}
.login-form-container {
  max-width: 460px;
  border: 2px solid #545454;
  border-radius: 8px;
  padding: 10px 45px;
}
