.yearCalendar .mbsc-calendar-month-title {
  text-align: left !important;
  color: #13b3e5;
}
.yearCalendar .mbsc-calendar-header .mbsc-material{
  display: none
}
.mbsc-calendar-grid-row .mbsc-calendar-grid-item {
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05) !important;
  border-radius: 8px !important;
  padding-top: 3.5rem;
}
.mbsc-calendar-grid-row {
  gap: 10px !important;
}
