.App {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 100%;
  text-align: center;
}

.App-Content {
  flex: 1 0 auto;
}
.App-Container {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  width: 95%;
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.footerContainer {
  width: 100%;
  text-align: center;
  margin: auto;
}

.footerInformationSection {
  background-color: #000;
  padding: 20px;
  color: #ffffff;
}

.footerSocialSection {
  width: 100%;
  background-color: #e8edef;
  color: #102040 !important;
  padding: 5px 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* toast styles */

.Toastify__toast-container {
  border-radius: 0.5rem;
  width: auto;
  font-size: 14px;
}
.Toastify__toast {
  min-height: 3rem;
}
.Toastify__toast-body {
  padding: 0 0.375rem;
  margin: 0;
}
.Toastify__toast--default {
  background: #fff;
  color: #aaa;
}
.Toastify__toast--info {
  background: #3498db;
}
.Toastify__toast--success {
  background: #07bc0c;
}
.Toastify__toast--warning {
  background: #f1c40f;
}
.Toastify__toast--error {
  background: #e74c3c;
}
.mbsc-material.mbsc-textfield {
  color: none !important;
  border: 1px solid #0a0a0a !important;
}
.mbsc-material.mbsc-textfield-box {
  padding: 2px 4px !important;
}
.mbsc-material.mbsc-textfield-box,
.mbsc-material.mbsc-textfield-inner-box {
  border-radius: 4px !important;
}
.mbsc-material.mbsc-calendar-height-md .mbsc-calendar-day,
.mbsc-material.mbsc-calendar-height-md .mbsc-calendar-day:after,
.mbsc-material.mbsc-calendar-height-md .mbsc-calendar-week-day {
  border: none !important;
}

.MuiPaginationItem-root {
  margin-top: 6px !important;
}
