/* .mbsc-calendar-controls {

} */
.mbsc-material.mbsc-calendar-height-md .mbsc-calendar-day,
.mbsc-material.mbsc-calendar-height-md .mbsc-calendar-week-day {
  border: none;
}
.mbsc-calendar-day-inner {
  border: none !important;
}
.mbsc-calendar-day-text {
  font-family: "Cabin", sans-serif;
  font-size: 17px !important;
  font-weight: 500 !important;
}
.mbsc-calendar-week-day {
  font-family: "Cabin", sans-serif;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: black !important;
}
/* .mbsc-calendar-day-outer {
  display: none !important;
} */
