.footer-logo-container {
  margin-right: 7rem;
  max-width: 215px;
}
.footerContainer {
  background-color: #000 !important;
}

@media (max-width: 900px) {
  .footer-logo-container {
    margin: auto !important;
    margin-right: auto !important;
  }
}

.footer-col-heading {
  margin-bottom: 20px !important;
  color: #ededed;
}

.footer-col-hyperlink {
  margin-bottom: 8px !important;
  color: #acacace2;
  font-size: 14px !important;
}
.social-icon {
  background-color: #0d0e10;
  padding: 5px;
  width: 24px;
  border-radius: 50px;
}
.footerSocialSection {
}
