.weekCalendar .mbsc-ios.mbsc-schedule-header-item-large {
  padding: 1rem 2rem !important;
  font-size: 17px !important;
  font-weight: 500 !important;
  color: #0a0a0a !important;
}
.weekCalendar .mbsc-ios.mbsc-schedule-header-dayname {
  font-size: 17px !important;
  font-weight: 500 !important;
}
.weekCalendar .mbsc-ios.mbsc-schedule-header-dayname {
  font-size: 17px !important;
  font-weight: 500 !important;
}
.weekCalendar .mbsc-ios.mbsc-schedule-time-indicator {
  display: none;
}
.weekCalendar .mbsc-ios.mbsc-schedule-header-day {
  font-size: 17px !important;
  font-weight: 500 !important;
}
.weekCalendar .mbsc-schedule-time.mbsc-ltr {
  font-size: 15px;
  font-weight: 500;
  text-align: right;
  color: #0a0a0a;
}
.weekCalendar .mbsc-schedule-grid-scroll,
.mbsc-timeline-grid-scroll {
  overflow-y: scroll !important;
}
.weekCalendar .mbsc-ios.mbsc-schedule-wrapper {
  border: 1px solid #808080 !important;
  border-radius: 10px !important;
}
.weekCalendar .mbsc-ios.mbsc-schedule-header,
.mbsc-ios.mbsc-schedule-header-item {
  border-top: 1px solid #808080;
}
.weekCalendar .mbsc-schedule-time-col,
.mbsc-timeline-resource-col {
  margin-right: 12px;
}
.weekCalendar .mbsc-schedule-grid-wrapper {
  height: 400px;
  overflow-y: hidden !important;
}
.weekCalendar .mbsc-schedule-all-day-item:first-child,
.mbsc-schedule-column:first-child {
  border-left: 1px solid #ccc;
}
.weekCalendar .mbsc-schedule-column {
  padding: 0 !important;
}
