.app-bar {
  box-shadow: none !important;
  background-color: #000 !important;
}

.app-bar-toolbar-container {
  max-width: 100% !important;
  min-height: 60px !important;

  padding-left: 50px;
  padding-right: 50px;
}
