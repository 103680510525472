.bigCalendar > .mbsc-calendar-header .mbsc-material {
  background-color: #13b3e5 !important;
}
.bigCalendar .mbsc-calendar-body {
  border: 3px solid #13b3e5 !important;
}
.bigCalendar {
  background-color: 3.56851px solid #13b3e5 !important;
  background-color: #13b3e5 !important;
  border-radius: 13px !important;
}
.bigCalendar .mbsc-material.mbsc-selected .mbsc-calendar-cell-text {
  background: #13b3e5 !important;
  border-color: #13b3e5 !important;
  color: #fff !important;
  border-radius: 50% !important;
}
.bigCalendar .mbsc-calendar-slide-active {
  border-radius: 0 0 11px 12px !important;
}
.bigCalendar .mbsc-calendar-cell-text {
  font-family: "Cabin", sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 19px !important;

  color: #333333 !important;
}
.bigCalendar
  .mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md
  .mbsc-calendar-day.mbsc-ltr,
.mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md
  .mbsc-calendar-marks.mbsc-ltr,
.mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md
  .mbsc-calendar-week-day.mbsc-ltr {
  text-align: center !important;
}
.bigCalendar .mbsc-material.mbsc-calendar-month-name.mbsc-ltr {
  display: none !important;
}
.bigCalendar
  .mbsc-material.mbsc-calendar-width-md
  .mbsc-calendar-day-labels
  .mbsc-calendar-day-inner {
  min-height: 0em !important;
}
.bigCalendar .mbsc-calendar-day-inner {
  height: 46px !important;
}
.hideMonth .mbsc-calendar-month {
  display: none !important;
}
.hideMonth .mbsc-calendar-year {
  color: #fff !important;
}
.hideMonth .mbsc-icon > svg {
  fill: #fff !important;
}
