.selectedColorBox {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.filledColorBox {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
}
.borderColorBox {
  height: 25px;
  width: 25px;
  padding: 2px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
