@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,600;0,700;1,600&family=Lora:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
  scrollbar-width: thin;
  height: 1px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 6px;
  scrollbar-width: thin;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;

  border-radius: 20px;
  width: 6px;
  background-clip: content-box;
  height: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
  width: 6px;
  height: 3px;
}

::-webkit-scrollbar-thumb:horizontal {
  scrollbar-width: thin;
}
