.activeButtonMode {
  border-radius: 28px;
  padding: 8px 15px;
  min-width: 120px;
  background-color: #102040;
  color: #fff;
  font-size: 16px;
}
.nonActiveButtonMode {
  border-radius: 28px;
  padding: 8px 15px;
  min-width: 120px;
  background-color: #102040 rgba(255, 255, 255, 0.651);
  font-size: 16px;
  color: #102040;
}
