.dailogCalendar .mbsc-calendar-cell-text {
  font-family: "Roboto", sans-serif !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;

  color: #0a0a0a !important;
}
.dailogCalendar .mbsc-material.mbsc-selected .mbsc-calendar-cell-text {
  background: #13b3e5 !important;
  border-color: #13b3e5 !important;
  color: #fff !important;
  border-radius: 50% !important;
}
.dailogCalendar
  .mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md
  .mbsc-calendar-day.mbsc-ltr,
.mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md
  .mbsc-calendar-marks.mbsc-ltr,
.mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md
  .mbsc-calendar-week-day.mbsc-ltr {
  text-align: center !important;
}
.dailogCalendar .mbsc-material.mbsc-calendar-month-name.mbsc-ltr {
  display: none !important;
}
.dailogCalendar .mbsc-calendar-week-day {
  font-family: "Roboto";
  font-weight: 500;
}
.dailogCalendar
  .mbsc-material.mbsc-calendar-width-md
  .mbsc-calendar-day-labels
  .mbsc-calendar-day-inner {
  min-height: 0em !important;
}
.dailogCalendar .mbsc-calendar-header {
  height: 46 !important;
}
.dailogCalendar .mbsc-calendar-day-inner {
  height: 35px !important;
}
.showMonth .mbsc-calendar-month {
  font-weight: 700 !important;
  color: #13b3e5 !important;
  display: inline;
}
.showMonth .mbsc-calendar-year {
  color: #13b3e5 !important;
  font-weight: 700 !important;
}
.showMonth .mbsc-icon > svg {
  fill: #fff !important;
}
