.imageContainer {
  background: linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

  border-radius: 10px;
  padding: 20px 25px;
  transform: all 0.5 ease-out 0.2;
}
.imageContainer:hover {
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.3);
}
.cta {
  background: #e8edef;
  border-radius: 30px;
  width: 85% !important;
  margin: 0 auto;
  padding: 40px 70px;
  margin-bottom: -160px;
  z-index: 100;
}
.contactUsImage {
  background-image: url("../../assests/image/contact-us/contactUs.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
