.react-flow__node-triangle {
  width: 0px !important;
  height: 0px !important;
  border-style: inset;
  border-width: 0 50px 80px 50px !important;
  border-color: transparent transparent #fbad19 transparent !important;
  float: left;
  transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  z-index: -100;
}

.triangle-node-text {
  text-align: center !important;
  color: #fff !important;
  font-size: 8px !important;
  top: 30px !important;
  left: -17px;
  position: relative !important;

  margin: 0px !important;
}

.btn {
  background: #3498db;
  background-image: -webkit-linear-gradient(top, #3498db, #2980b9);
  background-image: -moz-linear-gradient(top, #3498db, #2980b9);
  background-image: -ms-linear-gradient(top, #3498db, #2980b9);
  background-image: -o-linear-gradient(top, #3498db, #2980b9);
  background-image: linear-gradient(to bottom, #3498db, #2980b9);
  -webkit-border-radius: 4;
  -moz-border-radius: 4;
  border-radius: 4px;
  font-family: Arial;
  color: #ffffff;
  font-size: 8px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  margin-right: 10;
}

.btn:hover {
  cursor: pointer;
  background: #3cb0fd;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
  text-decoration: none;
}
.css-input {
  width: 95%;
  padding: 5px;
  font-size: 8px;
  border-width: 1px;
  border-color: #cccccc;
  background-color: #ffffff;
  color: #000000;
  border-style: solid;
  border-radius: 0px;
  box-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
  text-shadow: 0px 0px 5px rgba(66, 66, 66, 0.75);
}
.css-input:focus {
  outline: none;
}

.react-flow__handle {
  background: transparent !important;
  border: 0.1px solid transparent !important;
  border-radius: 100% !important;
  height: 0.2px !important;
  position: absolute !important;
  width: 0.2px !important;
  pointer-events: none !important;
}
.react-flow__node-oval {
  color: "#fff";
  background: #13b3e5;
  box-shadow: 0px 6.195px 18.585px rgba(0, 0, 0, 0.2);
  width: 100px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  border: none;
  color: #fff;
  margin: auto 0;
}
.react-flow__node-circle {
  min-height: 70px;
  min-width: 70px;
  border-radius: 50%;
  display: flex;
  color: #fff;
  background: #13b3e5;
  font-size: 8px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.react-flow__node-inputText {
  font-family: "Cabin", sans-serif !important;

  font-size: 8.302px !important;
}
.react-flow__edge-path {
  stroke: #333333 !important;
}
.react-flow__node-rectangle {
  font-family: "Cabin", sans-serif !important;
  padding: 4px 0px !important;
  min-height: 40px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  /* height: 100px; */
  width: 100px !important;
  z-index: 3;
  transform: translate(108px, 71.5px);
  pointer-events: all;
  opacity: 1;
  background: #f37684;
  box-shadow: 0px 6.195px 12.39px rgb(0 0 0 / 15%) !important;
  border-radius: 10px !important;
  color: white !important;
}
.react-flow__node-square {
  font-family: "Cabin", sans-serif !important;
  padding: 4px 0px !important;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  height: 70px;
  width: 70px !important;
  z-index: 3;
  transform: translate(108px, 71.5px);
  pointer-events: all;
  opacity: 1;
  background: #f37684;
  box-shadow: 0px 6.195px 12.39px rgb(0 0 0 / 15%) !important;
  border-radius: 10px !important;
  color: white !important;
}
.react-flow__node-parallelogram {
  width: 60px !important;
  height: 60px;

  background: #fbad19;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
}
.parallelogramText {
  transform: skewX(-10deg) !important;
  color: white !important;
}
.react-flow__node-inputTextMedium {
  color: #fbad19 !important;
  font-size: 12px !important;
}
.react-flow__node-inputTextEasy {
  color: #0e9a35 !important;
  font-size: 12px !important;
}
.react-flow__node-inputTextDifficult {
  color: #e52630 !important;
  font-size: 12px !important;
}
.react-flow__handle.connectable {
  width: 0px;
}
.react-flow__handle.connectable {
  /* display: ; */
}
.slide {
  bottom: 100px;
  right: -110%;
  display: hidden;
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.5s;
  animation: slide 0.5s forwards;
  animation-delay: 0.5s;
}
@-webkit-keyframes slide {
  100% {
    display: block;
    right: 30px;
  }
}

@keyframes slide {
  100% {
    display: block;
    right: 30px;
  }
}
